import React from "react"
import { Link } from "gatsby"

const Footer = () => (
  <div className="row">
    <div className="medium-4 columns">
      <h5 className="headings caps">Contact</h5>
      <p className="mb1">2010 Diamond Hill Road, Woonsocket, RI 02895</p>
      <p className="mb1">
        <a href="tel:4015260055">401-526-0055</a>
      </p>
      <p>
        <a href="mailto:sales@ssllc.com">sales@ssllc.com</a>
      </p>

      <div className="row columns">
        <h5 className="caps followus">Follow Us On:</h5>
        <div className="social-links">
          <ul>

            <li>
              <a target="_blank" href="http://www.linkedin.com/company/208136">
                <i className="icon-linkedin"></i>
              </a>
            </li>

            <li>
              <a
                target="_blank"
                href="http://www.youtube.com/user/SurplusSolutionsLLC"
              >
                <i className="icon-youtube-play"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <p className="bold">&copy; 2024 Surplus Solutions <br/> All rights reserved.</p>
    </div>
    <div className="medium-2 columns end">
      <h5 className="headings caps short">Menu</h5>

      <ul className="footer-menu">
        <li>
          <Link to="/buy-equipment">Buy Equipment</Link>
        </li>

        <li>
          <Link to="/sell-equipment">Sell Equipment</Link>
        </li>

        <li>
          <Link to="/auctions">Auctions</Link>
        </li>

        <li>
          <Link to="/browse-manufacturers">Manufacturers</Link>
        </li>

        <li>
          <Link to="/catalog/featured">Featured Items</Link>
        </li>

        <li>
          <Link to="/catalog/new-arrivals">New Arrivals</Link>
        </li>
        
        <li>
          <Link to="/email-sign-up">Sign up for Emails</Link>
        </li>

        <li>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </li>

        <li>
          <Link to="/terms-of-use">Terms of Use</Link>
        </li>

        <li>
          <Link to="/sitemap">Sitemap</Link>
        </li>
      </ul>
    </div>
  </div>
)

export default Footer
